import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet

			header={'Breadth First Search | Code Tutorial'}
			subHeader={''}
			description={'Breadth-first search (BFS) is an algorithm for traversing or searching tree or graph data structures. It starts at the tree root (or some arbitrary node of a graph, sometimes referred to as a \'search key\'), and explores all of the neighbor nodes at the present depth prior to moving on to the nodes at the next depth level.'}
			keywords={['bfs', 'breadth first search', 'breadth first search example', 'breadth first search algorithm']}
			embeddedVideo={'0OQjzWthrOY'}
			embeddedCode={'https://raw.githubusercontent.com/graphoarty/bfs/master/bfs.py'}		

			></CodeSnippet>
		);
	}
}

export default View;
